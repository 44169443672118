<script>
  import PageDataSidebar from '@/components/shared_components/page_data/PageDataSidebar';

  export default {
    extends: PageDataSidebar,
    computed: {
      mobTitle() { return this.$gettext('Documents'); },
      navLinksList() {
        return [{
          title: this.$gettext('Documents'),
          route: {name: 'ServerDocuments'}
        }, {
          title: this.$gettext('Signatures'),
          route: {name: 'ServerSignatures'}
        }];
      }
    }
  };
</script>
