<script>
  import DocumentsNav from '@/components/documents/DocumentsNav';
  import PageDataRoot from '@/components/shared_components/page_data/PageDataRoot';

  export default {
    components: {
      'sidebar-nav': DocumentsNav
    },
    extends: PageDataRoot,
    computed: {
      showMobNav() { return this.$route.name == 'ServerDocs'; }
    }
  };
</script>
